import React, { useEffect, useState, forwardRef, useRef } from "react";
import {
  Form,
  Button,
  Col,
  Row,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import ApiManager from "../utils/ApiManager";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import AddIcon from "@mui/icons-material/Add";

const CellSelector = forwardRef(
  (
    {
      selectedWarehouseAreaId,
      setSelectedWarehouseAreaId,
      selectedWarehouseAreaDescr,
      setSelectedWarehouseAreaDescr,
      selectedRack,
      setSelectedRack,
      selectedCol,
      setSelectedCol,
      selectedShelf,
      setSelectedShelf,
      idPrefix,
      editMode,
    },
    ref
  ) => {
    const rackRef = useRef(null);
    const colRef = useRef(null);
    const shelfRef = useRef(null);

    const [cells, setCells] = useState([]);
    const [editRack, setEditRack] = useState();
    const [editCol, setEditCol] = useState();
    const [editShelf, setEditShelf] = useState();

    useEffect(() => {
      const fetchData = async () => {
        await ApiManager.Top.cells(
          (data) => {
            setCells(data);
          },
          (error) => {
            console.error("Errore durante il recupero dei dati:", error);
          },
          () => {}
        );
      };
      fetchData();
    }, []);

    useEffect(() => {
      if (setSelectedWarehouseAreaDescr && cells?.warehouseAreas?.length > 0) {
        const selectedArea = cells?.warehouseAreas.find(
          (area) => area.warehouseAreaId === selectedWarehouseAreaId
        );
        setSelectedWarehouseAreaDescr(selectedArea?.warehouseAreaDescr || "");
      }
    }, [selectedWarehouseAreaId, cells, setSelectedWarehouseAreaDescr]);

    useEffect(() => {
      if (ref.current) {
        ref.current.focus();
      }
    }, [selectedWarehouseAreaId, selectedRack, selectedCol]);

    const handleWarehouseAreaChange = (e) => {
      setSelectedWarehouseAreaId(e.target.value);
      setSelectedRack("");
      setSelectedCol("");
      setSelectedShelf("");
      setTimeout(() => {
        if (rackRef.current) {
          rackRef.current.focus();
        }
      }, 0);
    };

    const handleRackChange = (e) => {
      setSelectedRack(e.target.value);
      setSelectedCol("");
      setSelectedShelf("");
      setTimeout(() => {
        if (colRef.current) {
          colRef.current.focus();
        }
      }, 0);
    };

    const handleColChange = (e) => {
      setSelectedCol(e.target.value);
      setSelectedShelf("");
      setTimeout(() => {
        if (shelfRef.current) {
          shelfRef.current.focus();
        }
      }, 0);
    };

    const handleShelfChange = (e) => {
      setSelectedShelf(e.target.value);
    };

    const handleTrasloClick = () => {
      setSelectedWarehouseAreaId("51");
      setSelectedRack("1");
      setSelectedCol("A");
      setSelectedShelf("1");
      setTimeout(() => {
        if (rackRef.current) {
          rackRef.current.focus();
        }
      }, 0);
    };

    const handleEditRackClick = () => {
      setEditRack(true);
    };

    const handleEditColClick = () => {
      setEditCol(true);
    };

    const handleEditShelfClick = () => {
      setEditShelf(true);
    };

    return (
      <div>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId={`${idPrefix}-warehouseAreaSelect`}>
              <Form.Label className="half-parent-text">Area</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  as="select"
                  value={selectedWarehouseAreaId}
                  onChange={handleWarehouseAreaChange}
                  ref={ref}
                >
                  <option value=""></option>
                  {cells?.warehouseAreas?.map((area) => (
                    <option
                      key={area.warehouseAreaId}
                      value={area.warehouseAreaId}
                    >
                      {area.warehouseAreaDescr}
                    </option>
                  ))}
                </Form.Control>

                <Button
                  variant="primary"
                  onClick={handleTrasloClick}
                  title="Traslo"
                >
                  <PrecisionManufacturingIcon />
                </Button>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId={`${idPrefix}-rackSelect`}>
              <Form.Label className="half-parent-text">Scaffale</Form.Label>
              {editRack ? (
                <FormControl
                  type="text"
                  value={selectedRack}
                  onChange={(e) => setSelectedRack(e.target.value)}
                  ref={rackRef}
                />
              ) : (
                <InputGroup>
                  <Form.Control
                    as="select"
                    value={selectedRack}
                    onChange={handleRackChange}
                    disabled={!selectedWarehouseAreaId}
                    ref={rackRef}
                  >
                    <option value=""></option>
                    {cells?.warehouseAreas
                      ?.find(
                        (area) =>
                          area.warehouseAreaId === selectedWarehouseAreaId
                      )
                      ?.racks?.map((rack) => (
                        <option
                          key={`${selectedWarehouseAreaId}_${rack.rack}`}
                          value={rack.rack}
                        >
                          {rack.rack}
                        </option>
                      ))}
                  </Form.Control>
                  <Button
                    variant="primary"
                    onClick={handleEditRackClick}
                    title="Aggiungi"
                    hidden={!editMode}
                  >
                    <AddIcon />
                  </Button>
                </InputGroup>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId={`${idPrefix}-colSelect`}>
              <Form.Label className="half-parent-text">Colonna</Form.Label>
              {editCol ? (
                <FormControl
                  type="text"
                  value={selectedCol}
                  onChange={(e) => setSelectedCol(e.target.value)}
                  ref={colRef}
                />
              ) : (
                <InputGroup>
                  <Form.Control
                    as="select"
                    value={selectedCol}
                    onChange={handleColChange}
                    disabled={!selectedRack}
                    ref={colRef}
                  >
                    <option value=""></option>
                    {cells?.warehouseAreas
                      ?.find(
                        (area) =>
                          area.warehouseAreaId === selectedWarehouseAreaId
                      )
                      ?.racks?.find((rack) => rack.rack === selectedRack)
                      ?.cols?.map((col) => (
                        <option
                          key={`${selectedWarehouseAreaId}_${selectedRack}_${col.col}`}
                          value={col.col}
                        >
                          {col.col}
                        </option>
                      ))}
                  </Form.Control>
                  <Button
                    variant="primary"
                    onClick={handleEditColClick}
                    title="Aggiungi"
                    hidden={!editMode}
                  >
                    <AddIcon />
                  </Button>
                </InputGroup>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId={`${idPrefix}-shelfSelect`}>
              <Form.Label className="half-parent-text">Ripiano</Form.Label>
              {editShelf ? (
                <FormControl
                  type="text"
                  value={selectedShelf}
                  onChange={(e) => setSelectedShelf(e.target.value)}
                  ref={shelfRef}
                />
              ) : (
                <InputGroup>
                  <Form.Control
                    as="select"
                    value={selectedShelf}
                    onChange={handleShelfChange}
                    disabled={!selectedCol}
                    ref={shelfRef}
                  >
                    <option value=""></option>
                    {cells?.warehouseAreas
                      ?.find(
                        (area) =>
                          area.warehouseAreaId === selectedWarehouseAreaId
                      )
                      ?.racks?.find((rack) => rack.rack === selectedRack)
                      ?.cols?.find((col) => col.col === selectedCol)
                      ?.shelfs?.map((shelf) => (
                        <option
                          key={`${selectedWarehouseAreaId}_${selectedRack}_${selectedCol}_${shelf.shelf}`}
                          value={shelf.shelf}
                        >
                          {shelf.shelf}
                        </option>
                      ))}
                  </Form.Control>
                  <Button
                    variant="primary"
                    onClick={handleEditShelfClick}
                    title="Aggiungi"
                    hidden={!editMode}
                  >
                    <AddIcon />
                  </Button>
                </InputGroup>
              )}
            </Form.Group>
          </Col>
        </Row>
      </div>
    );
  }
);

export default CellSelector;
