import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import CellSelector from "./CellSelector";
import ApiManager from "../utils/ApiManager"; // Importa ApiManager

import { Button, Col, Row } from "react-bootstrap";
import CellProducts from "./CellProducts";
import ModalMoveProducts from "./ModalMoveProducts";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import ModalManualMovements from "./ModalManualMovements";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const Cells = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedWarehouseAreaId, setSelectedWarehouseAreaId] = useState("");
  const [selectedWarehouseAreaDescr, setSelectedWarehouseAreaDescr] =
    useState("");
  const [selectedRack, setSelectedRack] = useState("");
  const [selectedCol, setSelectedCol] = useState("");
  const [selectedShelf, setSelectedShelf] = useState("");
  const [showModalMove, setShowModalMove] = useState(false);
  const [showModalManMov, setShowModalManMov] = useState(false);
  const [selectedMovReason, setSelectedMovReason] = useState(0);
  const warehouseAreaRef = useRef(null);

  const handleCheckedChange = (list) => {
    if (list.length > 0) {
      const updatedMovements = data.map((movement) => {
        const listItem = list.find((pl) => pl.id === movement.id);

        if (listItem) {
          // Usa il valore selected dell'ultimo elemento trovato (o di un criterio specifico)
          const selected = listItem.selected;

          // Ritorna un nuovo oggetto movement con selected aggiornato
          return {
            ...movement,
            selected: selected,
          };
        }

        return movement; // Se non trovato, ritorna l'oggetto movement originale
      });

      setData(updatedMovements);
    }
  };

  const fetchData = async () => {
    setError(null);
    if (
      selectedWarehouseAreaId &&
      selectedRack &&
      selectedCol &&
      selectedShelf
    ) {
      await ApiManager.Top.cellProducts(
        {
          warehouseAreaId: selectedWarehouseAreaId,
          rack: selectedRack,
          col: selectedCol,
          shelf: selectedShelf,
        },
        (data) => {
          setData(data);
        },
        (error) => {
          setError(error);
        },
        () => {}
      );
    } else {
      setData([]);
    }
  };

  useEffect(() => {
    if (warehouseAreaRef.current) {
      warehouseAreaRef.current.focus();
    }

    fetchData();
  }, [selectedWarehouseAreaId, selectedRack, selectedCol, selectedShelf]);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const warehouseAreaId = queryParams.get("warehouseAreaId");
    const rack = queryParams.get("rack");
    const col = queryParams.get("col");
    const shelf = queryParams.get("shelf");

    if (warehouseAreaId) setSelectedWarehouseAreaId(warehouseAreaId);
    if (rack) setSelectedRack(rack);
    if (col) setSelectedCol(col);
    if (shelf) setSelectedShelf(shelf);
  }, [location.search]);

  if (error) return <p>Error: {error.message}</p>;

  const handleOpenModalMove = () => {
    setShowModalMove(true);
  };

  const handleOpenModalManMov = (movReason) => () => {
    setSelectedMovReason(movReason);
    setShowModalManMov(true);
  };

  const handleSaveModal = async (data) => {
    fetchData(); // Refresh data after modal save
  };

  return (
    <div className="cell-products">
      <CellSelector
        idPrefix="Cells"
        ref={warehouseAreaRef}
        selectedWarehouseAreaId={selectedWarehouseAreaId}
        setSelectedWarehouseAreaId={setSelectedWarehouseAreaId}
        setSelectedWarehouseAreaDescr={setSelectedWarehouseAreaDescr}
        selectedRack={selectedRack}
        setSelectedRack={setSelectedRack}
        selectedCol={selectedCol}
        setSelectedCol={setSelectedCol}
        selectedShelf={selectedShelf}
        setSelectedShelf={setSelectedShelf}
      />
      <Row className="mb-3 text-end">
        <Col>
          <Button
            title="Carica"
            onClick={handleOpenModalManMov(1)}
            variant="primary"
            disabled={
              !selectedWarehouseAreaId ||
              !selectedRack ||
              !selectedCol ||
              !selectedShelf
            }
          >
            <CloudUploadIcon />
          </Button>&nbsp;
          <Button
            title="Sposta"
            onClick={handleOpenModalMove}
            variant="primary"
            disabled={data?.filter((movement) => movement.selected).length === 0}
          >
            <MoveUpIcon />
          </Button>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <CellProducts
            idPrefix={"Cells"}
            label={"Articoli"}
            showSelectorCol={true}
            data={data}
            setData={setData}
            showProductLink={true}
            showYearCol={true}
            movReason={1}
          />
        </Col>
      </Row>
      <ModalMoveProducts
        movements={data}
        onCheckedChange={handleCheckedChange}
        showModal={showModalMove}
        setShowModal={setShowModalMove}
        onSaveModal={handleSaveModal}
      />
      <ModalManualMovements
        movReason={selectedMovReason}
        selectedWarehouseAreaId={selectedWarehouseAreaId}
        selectedWarehouseAreaDescr={selectedWarehouseAreaDescr}
        selectedRack={selectedRack}
        selectedCol={selectedCol}
        selectedShelf={selectedShelf}
        showModal={showModalManMov}
        setShowModal={setShowModalManMov}
        onSaveModal={handleSaveModal}
      />
    </div>
  );
};

export default Cells;
