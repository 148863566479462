import React from "react";

const RowCountLabel = ({ count, label, htmlFor }) => {
  if (!label) {
    label = "Righe trovate";
  }
  return (
    <span className="half-parent-text" htmlFor={htmlFor}>
      {label}: <strong>{count ?? 0}</strong>
    </span>
  );
};

export default RowCountLabel;