import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Alert } from "react-bootstrap";
import CellProducts from "./CellProducts";
import ApiManager from "../utils/ApiManager";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ProductSearch from "./ProductSearch";
import CellSelector from "./CellSelector";
import { v4 as uuidv4 } from "uuid";

const ModalManualMovements = ({
  movReason,
  selectedWarehouseAreaId: initialSelectedWarehouseAreaId,
  selectedWarehouseAreaDescr: initialSelectedWarehouseAreaDescr,
  selectedRack: initialSelectedRack,
  selectedCol: initialSelectedCol,
  selectedShelf: initialSelectedShelf,
  showModal,
  setShowModal,
  onSaveModal,
}) => {
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState("");
  const [productList, setProductList] = useState([]);
  const [selectedWarehouseAreaId, setSelectedWarehouseAreaId] = useState(
    initialSelectedWarehouseAreaId
  );
  const [selectedWarehouseAreaDescr, setSelectedWarehouseAreaDescr] = useState(
    initialSelectedWarehouseAreaDescr
  );
  const [selectedRack, setSelectedRack] = useState(initialSelectedRack);
  const [selectedCol, setSelectedCol] = useState(initialSelectedCol);
  const [selectedShelf, setSelectedShelf] = useState(initialSelectedShelf);
  const warehouseAreaRef = useRef(null);

  useEffect(() => {
    if (showModal) {
      setValidationError("");
      fetchData();
    }
  }, [showModal]);

  useEffect(() => {
    // Aggiorna i valori nel CellSelector quando cambiano i props iniziali
    setSelectedWarehouseAreaId(initialSelectedWarehouseAreaId);
    setSelectedWarehouseAreaDescr(initialSelectedWarehouseAreaDescr);
    setSelectedRack(initialSelectedRack);
    setSelectedCol(initialSelectedCol);
    setSelectedShelf(initialSelectedShelf);
  }, [initialSelectedWarehouseAreaId, initialSelectedWarehouseAreaDescr, initialSelectedRack, initialSelectedCol, initialSelectedShelf]);

  const fetchData = async () => {
    setError(null);
    await ApiManager.Top.cellProducts(
      {},
      (data) => {},
      (error) => {
        setError(error);
      },
      () => {}
    );
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleProductAdded = async (product) => {
    if (
      product &&
      !productList?.some((p) => p.selected && p.id === product.id)
    ) {
      let products = [...productList];
      products.push({
        id: product.id,
        selected: true,
        productId: product.id,
        productDescr: product.descr,
        productYear: product.productYear,
        supplierId: product.supplierId,
        selectedQty: 4,
      });
      setProductList(products);
    }
  };

  const saveModal = async () => {
    if (error) {
      setValidationError(error);
      return;
    }

    const updatedProducts = productList.map((product) => ({
      ...product,
      supplierId: product.selectedSupplierId,
      productYear: product.selectedYear,
      qty: product.selectedQty,
    }));

    const newGuid = uuidv4();

    if (movReason === 1) {
      await ApiManager.Top.stockIncomings(
        newGuid,
        updatedProducts,
        selectedWarehouseAreaId,
        selectedWarehouseAreaDescr,
        selectedRack,
        selectedCol,
        selectedShelf,
        (data) => {
          onSaveModal(data);
          setShowModal(false);
        },
        (error) => {
          console.error("Errore durante il salvataggio:", error);
          setValidationError("Errore durante il salvataggio.");
        },
        () => {}
      );
    }
    // else if (movReason === 0) {
    //   await ApiManager.Top.stockOutgoings(
    //     null,
    //     updatedProducts,
    //     selectedWarehouseAreaId,
    //     selectedWarehouseAreaDescr,
    //     selectedRack,
    //     selectedCol,
    //     selectedShelf,
    //     (data) => {
    //       onSaveModal(data);
    //       setShowModal(false);
    //     },
    //     (error) => {
    //       console.error("Errore durante il salvataggio:", error);
    //       setValidationError("Errore durante il salvataggio.");
    //     },
    //     () => {}
    //   );
    // }
  };

  return (
    <Modal
      className="incomingModals"
      show={showModal}
      onHide={closeModal}
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <strong className="header-text">{movReason === 1 ? "Carico" : "Scarico"}</strong>
      </Modal.Header>
      <Modal.Body>
        {validationError && <Alert variant="danger">{validationError}</Alert>}
        <ProductSearch onProductAdded={handleProductAdded} />
        <CellProducts
          idPrefix="ModalMoveProducts"
          label="Articoli da caricare"
          data={productList?.filter((row) => row.selected)}
          setData={setProductList}
          showSelectorCol={true}
          editQty={true}
          freeQty={true}
          editSupplier={true}
          showYearCol={true}
          editYear={true}
          showPositionCol={false}
          movReason={movReason}
        />
        <strong className="header-text">Ubicazione di destinazione</strong>
        <CellSelector
          idPrefix="ModalManualMovements"
          ref={warehouseAreaRef}
          selectedWarehouseAreaId={selectedWarehouseAreaId}
          setSelectedWarehouseAreaId={setSelectedWarehouseAreaId}
          selectedWarehouseAreaDescr={selectedWarehouseAreaDescr}
          setSelectedWarehouseAreaDescr={setSelectedWarehouseAreaDescr}
          selectedRack={selectedRack}
          setSelectedRack={setSelectedRack}
          selectedCol={selectedCol}
          setSelectedCol={setSelectedCol}
          selectedShelf={selectedShelf}
          setSelectedShelf={setSelectedShelf}
          editMode={true}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          title="Salva"
          variant="primary"
          onClick={saveModal}
          disabled={
            !selectedWarehouseAreaId ||
            !selectedRack ||
            !selectedCol ||
            !selectedShelf ||
            productList?.filter((product) => product.selected).length === 0
          }
        >
          <SaveAltIcon />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalManualMovements;
