class DataManager {
  static get Date() {
    return class {
      static get oneDayMs() {
        return 86400000;
      }

      static addDays(fromDate, days) {
        if (!fromDate || fromDate === "") {
          fromDate = "1900-01-01";
        }
        return new Date(new Date(fromDate).getTime() + days * this.oneDayMs)
          .toISOString()
          .substring(0, 10);
      }

      // static toIso(date) {
      //   if (!date || date === "") {
      //     date = "1900-01-01";
      //   }
      //   return new Date(date).toISOString().substring(0, 10);
      // }

      static toIso(date) {
        if (!date) {
            date = "1900-01-01";
        }
    
        let localDate;
        if (typeof date === "string" || date instanceof String) {
            localDate = new Date(date);
        } else if (date instanceof Date) {
            localDate = date;
        } else {
            throw new Error("Invalid date input");
        }
    
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, '0'); // mesi da 0 a 11, quindi aggiungi 1
        const day = String(localDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

      static format(date, formatString) {
        if (!date) return "";

        const options = {};
        switch (formatString) {
          case "dd/MM/yyyy":
            options.day = "2-digit";
            options.month = "2-digit";
            options.year = "numeric";
            break;
          case "dd/MM/yy":
            options.day = "2-digit";
            options.month = "2-digit";
            options.year = "2-digit";
            break;
          case "dd/MM/yyyy HH:mm:ss":
            options.day = "2-digit";
            options.month = "2-digit";
            options.year = "numeric";
            options.hour = "2-digit";
            options.minute = "2-digit";
            options.second = "2-digit";
            break;
          case "dd/MM/yyyy HH:mm":
            options.day = "2-digit";
            options.month = "2-digit";
            options.year = "numeric";
            options.hour = "2-digit";
            options.minute = "2-digit";
            break;
          case "dd/MM/yy HH:mm:ss":
            options.day = "2-digit";
            options.month = "2-digit";
            options.year = "2-digit";
            options.hour = "2-digit";
            options.minute = "2-digit";
            options.second = "2-digit";
            break;
          case "dd/MM/yy HH:mm":
            options.day = "2-digit";
            options.month = "2-digit";
            options.year = "2-digit";
            options.hour = "2-digit";
            options.minute = "2-digit";
            break;
          case "yyyy-MM-dd":
            options.year = "numeric";
            options.month = "2-digit";
            options.day = "2-digit";
            break;
          default:
            break;
        }

        return new Date(date)
          .toLocaleDateString("it-IT", options)
          .replace(",", "");
      }

      static getCurrentFormattedDateTime() {
        const now = new Date();
        const year = now.getFullYear().toString().slice(-2);
        const month = (now.getMonth() + 1).toString().padStart(2, "0");
        const day = now.getDate().toString().padStart(2, "0");
        const hours = now.getHours().toString().padStart(2, "0");
        const minutes = now.getMinutes().toString().padStart(2, "0");
        const seconds = now.getSeconds().toString().padStart(2, "0");
        const milliseconds = now.getMilliseconds().toString().padStart(3, "0");

        return `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;
      }
    };
  }

  static get Decimal() {
    return class {
      static format(value, decimals = 2, integers = 0) {
        if (value === null || value === undefined) {
          return ""; // Handle null or undefined values gracefully
        }

        // Convert value to string
        let valueString = value.toFixed(decimals || 0);

        // Split into integer and decimal parts
        let [integerPart, decimalPart] = valueString.split(".");

        // Add leading zeros if necessary
        if (integerPart.length < integers) {
          integerPart = "0".repeat(integers - integerPart.length) + integerPart;
        }

        // Format the number with Italian locale
        let formattedValue = integerPart;
        if (decimals > 0 && decimalPart) {
          formattedValue += `.${decimalPart}`;
        }
        return formattedValue.toLocaleString("it-IT", {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        });
      }
    };
  }
}

export default DataManager;
