import axios from "axios";
import { setLoading } from "../contexts/LoadingContext";
import { getCurrentUser, getLoginIsValid } from "../contexts/AuthContext";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();
class ApiManager {

  static Top = class {
    static prefix = "api/top";

    static async stockChangesInsert(
      pickingListId,
      movements,
      onSuccess,
      onError,
      onFinally
    ) {
      return await ApiManager.callApi(
        "put",
        ApiManager.getEndpoint(
          this.prefix,
          `stock-changes-insert?createdby=${
            getCurrentUser()?.username
          }&pickinglistid=${pickingListId || ""}`
        ),
        movements,
        onSuccess,
        onError,
        onFinally
      );
    }

    static async stockIncomings(
      pickingListId,
      incomings,
      toWarehouseAreaId,
      toWarehouseAreaDescr,
      toRack,
      toCol,
      toShelf,
      onSuccess,
      onError,
      onFinally
    ) {
      let hasError = false;

      // Callback per gestire il successo di entrambe le operazioni
      const handleSuccess = () => {
        if (!hasError) {
          onSuccess();
        }
        onFinally();
      };

      // Callback per gestire l'errore di una delle operazioni
      const handleError = (error) => {
        hasError = true;
        onError(error);
        onFinally();
      };

      incomings = incomings.map((movement) => ({
        ...movement,
        movReason: 1,
        warehouseAreaId: toWarehouseAreaId,
        warehouseAreaDescr: toWarehouseAreaDescr,
        rack: toRack,
        col: toCol,
        shelf: toShelf,
      }));

      try {
        await ApiManager.Top.stockChangesInsert(
          pickingListId,
          incomings,
          () => {},
          handleError,
          () => {}
        );
      } catch (error) {
        handleError(error);
        return;
      }

      handleSuccess();
    }

    static async stockOutgoings(
      pickingListId,
      outgoings,
      onSuccess,
      onError,
      onFinally
    ) {
      let hasError = false;

      // Callback per gestire il successo di entrambe le operazioni
      const handleSuccess = () => {
        if (!hasError) {
          onSuccess();
        }
        onFinally();
      };

      // Callback per gestire l'errore di una delle operazioni
      const handleError = (error) => {
        hasError = true;
        onError(error);
        onFinally();
      };

      // outgoings = outgoings.map((movement) => ({
      //   ...movement,
      //   movReason: -1,
      // }));

      let movements = outgoings.flatMap((movement) =>
        movement.pickingList
          .filter((pick) => pick.selectedQty > 0)
          .map((pick) => ({
            ...pick,
            qty: pick.selectedQty,
            docTypeId: movement.docTypeId,
            docTypeDescr: movement.docTypeDescr,
            docYear: movement.docYear,
            docNumb: movement.docNumb,
            docSeries: movement.docSeries,
            docRowNumb: movement.docRowNumb,
            docDate: movement.docDate,
            customerId: movement.customerId,
            customerDescr: movement.customerDescr,
            movReason: movement.movReason,
            transpReasonId: movement.transpReasonId,
            transpReasonDescr: movement.transpReasonDescr,
          }))
      );

      try {
        await ApiManager.Top.stockChangesInsert(
          pickingListId,
          movements,
          () => {},
          handleError,
          () => {}
        );
      } catch (error) {
        handleError(error);
        return;
      }

      handleSuccess();
    }

    static async stockMoves(
      movements,
      toWarehouseAreaId,
      toWarehouseAreaDescr,
      toRack,
      toCol,
      toShelf,
      onSuccess,
      onError,
      onFinally
    ) {
      let hasError = false;

      // Callback per gestire il successo di entrambe le operazioni
      const handleSuccess = () => {
        if (!hasError) {
          onSuccess();
        }
        onFinally();
      };

      // Callback per gestire l'errore di una delle operazioni
      const handleError = (error) => {
        hasError = true;
        onError(error);
        onFinally();
      };

      const outgoings = movements.map((movement) => ({
        ...movement,
        movReason: -1,
      }));

      const incomings = movements.map((movement) => ({
        ...movement,
        movReason: 1,
        warehouseAreaId: toWarehouseAreaId,
        warehouseAreaDescr: toWarehouseAreaDescr,
        rack: toRack,
        col: toCol,
        shelf: toShelf,
      }));

      movements = [...outgoings, ...incomings];

      try {
        await ApiManager.Top.stockChangesInsert(
          null,
          movements,
          () => {},
          handleError,
          () => {}
        );
      } catch (error) {
        handleError(error);
        return;
      }

      handleSuccess();
    }

    static async cellProducts(payload, onSuccess, onError, onFinally) {
      return await ApiManager.callApi(
        "post",
        ApiManager.getEndpoint(this.prefix, "cell-products"),
        payload,
        (data) => {
          let products = data?.products?.map((row) => ({
            ...row,
            selected: false,
          }));
          onSuccess(products);
        },
        onError,
        onFinally
      );
    }

    static async cells(onSuccess, onError, onFinally) {
      return await ApiManager.callApi(
        "get",
        ApiManager.getEndpoint(this.prefix, "cells"),
        null,
        onSuccess,
        onError,
        onFinally
      );
    }

    static async login(payload, onSuccess, onError, onFinally) {
      return await ApiManager.callApi(
        "post",
        ApiManager.getEndpoint(this.prefix, "login"),
        payload,
        onSuccess,
        onError,
        onFinally,
        true
      );
    }

    static async movements(payload, onSuccess, onError, onFinally) {
      return await ApiManager.callApi(
        "post",
        ApiManager.getEndpoint(this.prefix, "movements"),
        payload,
        (data) => {
          let movements = data?.movements?.map((row) => ({
            ...row,
            selected: false,
          }));
          onSuccess({ ...data, movements });
        },
        onError,
        onFinally
      );
    }

    static async products(payload, onSuccess, onError, onFinally) {
      return await ApiManager.callApi(
        "post",
        ApiManager.getEndpoint(this.prefix, "products"),
        payload,
        onSuccess,
        onError,
        onFinally
      );
    }

    static async contacts(payload, onSuccess, onError, onFinally) {
      return await ApiManager.callApi(
        "post",
        ApiManager.getEndpoint(this.prefix, "contacts"),
        payload,
        onSuccess,
        onError,
        onFinally
      );
    }

    static async pickingLists(payload, onSuccess, onError, onFinally) {
      return await ApiManager.callApi(
        "post",
        ApiManager.getEndpoint(this.prefix, "picking-lists"),
        payload,
        onSuccess,
        onError,
        onFinally
      );
    }

    static async dbInfo(onSuccess, onError, onFinally) {
      return await ApiManager.callApi(
        "get",
        ApiManager.getEndpoint(this.prefix, "db-info"),
        null,
        onSuccess,
        onError,
        onFinally
      );
    }
  };

  static getEndpoint(prefix, endpoint) {
    return `/${prefix}/${endpoint}`;
  }

  static async callApi(
    method,
    endpoint,
    payload,
    onSuccess,
    onError,
    onFinally,
    allowAnonymous = false
  ) {
    let response = null;
    try {
      const config = { headers: {} };
      if (!allowAnonymous && getLoginIsValid()) {
        const token = getCurrentUser()?.token;
        config.headers.Authorization = `Bearer ${token}`;
      }
      setLoading(true);
      switch (method.toLowerCase()) {
        case "get":
          response = await axios.get(endpoint, config);
          break;
        case "post":
          response = await axios.post(endpoint, payload, config);
          break;
        case "put":
          response = await axios.put(endpoint, payload, config);
          break;
        case "delete":
          response = await axios.delete(endpoint, { ...config, data: payload });
          break;
        default:
          throw new Error(`Unsupported HTTP method: ${method}`);
      }

      // Esegue onSuccess se è definito il callback
      if (onSuccess) {
        onSuccess(response.data);
      }
    } catch (error) {
      // Esegue onError se è definito il callback
      if (!allowAnonymous && error.response?.status === 401) {
        history.push("/login");
      } else if (onError) {
        onError(error);
      } else {
        console.error(`Error on ${endpoint}:`, error);
      }
    } finally {
      // Esegue onFinally se è definito il callback, impostando loading a false
      if (onFinally) {
        onFinally(false);
      }
      setLoading(false);
    }
  }
}

export default ApiManager;
