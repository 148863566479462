import React from "react";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import Layout from "./components/Layout";
import "./site.css";
import { LoadingProvider } from "./contexts/LoadingContext";
import Loading from "./components/Loading";
import { AuthProvider } from "./contexts/AuthContext";
import ProtectedRoute from "./routes/ProtectedRoute";
import { AppProvider } from "./contexts/AppContext";
import { DbProvider } from "./contexts/DbContext";

const App = () => {
  return (
    <AppProvider>
        <DbProvider>
          <AuthProvider>
            <LoadingProvider>
              <Layout>
                <Routes>
                  {AppRoutes.map((route, index) => {
                    const { element, isProtected, ...rest } = route;
                    if (isProtected) {
                      return (
                        <Route
                          key={index}
                          {...rest}
                          element={<ProtectedRoute element={element} />}
                        />
                      );
                    }
                    return <Route key={index} {...rest} element={element} />;
                  })}
                </Routes>
                <Loading />
              </Layout>
            </LoadingProvider>
          </AuthProvider>
        </DbProvider>
    </AppProvider>
  );
};

export default App;
