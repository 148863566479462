// import DdtAcquisto from "../components/DdtAcquisto";
// import DdtVendita from "../components/DdtVendita";
// import Giacenze from "../components/Giacenze";
import Movements from "../components/Movements";
import Login from "../components/Login";
import { Home } from "../components/Home";
import Cells from "../components/Cells";
import Products from "../components/Products";
import PickingLists from "../components/PickingLists";

const AppRoutes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Home />,
    isProtected: true,
    index: true,
  },
  {
    path: "/carichi",
    element: <Movements movReason={1} />,
    isProtected: true,
  },
  {
    path: "/scarichi",
    element: <Movements movReason={-1} />,
    isProtected: true,
  },
  {
    path: "/liste",
    element: <PickingLists />,
    isProtected: true,
  },
  {
    path: "/celle",
    element: <Cells />,
    isProtected: true,
  },
  {
    path: "/articoli",
    element: <Products />,
    isProtected: true,
  },
];

export default AppRoutes;
