import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import RowCountLabel from "./RowCountLabel";
import CellProducts from "./CellProducts";
import ApiManager from "../utils/ApiManager";
import ModalMoveProducts from "./ModalMoveProducts";
import ProductSearch from "./ProductSearch";

const Products = () => {
  const [data, setData] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleCheckedChange = (list) => {
    if (list.length > 0) {
      const updatedMovements = data.map((movement) => {
        const listItem = list.find((pl) => pl.id === movement.id);

        if (listItem) {
          const selected = listItem.selected;
          return {
            ...movement,
            selected: selected,
          };
        }

        return movement;
      });

      setData(updatedMovements);
    }
  };

  const fetchData = async () => {
    if (selectedProductId) {
      await ApiManager.Top.cellProducts(
        { productId: selectedProductId },
        (data) => {
          setData(data);
        },
        (error) => {
          console.error("Error fetching cell-products:", error);
        },
        () => {}
      );
    } else {
      setData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedProductId]);

  const handleProductChanged = (product) => {
    setSelectedProductId(product?.id);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleSaveModal = async (data) => {
    fetchData(); // Refresh data after modal save
  };

  return (
    <div className="products">
      <ProductSearch onlyInStock={true} onProductChanged={handleProductChanged} />
      <Row className="mb-3">
        <Col>
          <RowCountLabel
            count={data?.length}
            label="Ubicazioni"
            htmlFor="productSelect"
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="text-end">
          <Button
            onClick={handleOpenModal}
            variant="primary"
            className="float-end"
            title="Sposta"
            disabled={
              data?.filter((movement) => movement.selected).length === 0
            }
          >
            <MoveUpIcon />
          </Button>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <CellProducts
            idPrefix={"Products"}
            label={"Ubicazioni"}
            data={data}
            setData={setData}
            showSelectorCol={true}
            showPositionLink={true}
            showPositionCol={true}
            showYearCol={true}
            movReason={1}
          />
        </Col>
      </Row>
      <ModalMoveProducts
        movements={data}
        onCheckedChange={handleCheckedChange}
        showModal={showModal}
        setShowModal={setShowModal}
        onSaveModal={handleSaveModal}
      />
    </div>
  );
};

export default Products;
