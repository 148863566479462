import React from "react";
import { Form } from "react-bootstrap";

const CheckboxComponent = ({ id, label, checked, onChange, hidden, disabled  }) => {
  const handleCheckboxChange = () => {
    onChange(id);
  };

  if (hidden) {
    return null;
  }

  return (
    <Form.Group controlId={`checkbox-${id}`}>
      <Form.Check 
        type="checkbox"
        label={label}
        checked={checked}
        onChange={handleCheckboxChange}
        className="large-checkbox"
        disabled={disabled}
      />
    </Form.Group>
  );
};

export default CheckboxComponent;
