import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import DataManager from "../utils/DataManager";
import ProductLink from "./ProductLink";
import RowCountLabel from "./RowCountLabel";
import CheckboxComponent from "./CheckboxComponent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import ApiManager from "../utils/ApiManager";

const CellProducts = ({
  label,
  data,
  setData,
  movReason, // 1=Incomings, -1=Outgoings
  showSelectorCol,
  showDocCol,
  showProductCol = true,
  showProductLink,
  showYearCol,
  editYear,
  editSupplier,
  showPositionCol,
  showPositionLink,
  showPickingList,
  editPickingList,
  editQty,
  freeQty,
  idPrefix = "CellProducts",
  onCellSelect,
}) => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 6 }, (_, i) => currentYear - i);
  const [suppliers, setSuppliers] = useState([]);

  useEffect(() => {
    if (editSupplier) {
      const fetchData = async () => {
        await ApiManager.Top.contacts(
          { contactType: "F" },
          (data) => {
            let contacts = data.contacts || [];
            contacts.unshift({ id: null, descr: "" });
            setSuppliers(contacts);
          },
          (error) => {
            console.error("Error fetching suppliers:", error);
          },
          () => {}
        );
      };
      fetchData();
    }
  }, []);

  // Handler for checkbox change
  const handleCheckboxChange = (rowId) => {
    const updatedRows = data.map((row) =>
      row.id === rowId ? { ...row, selected: !row.selected } : row
    );
    setData(updatedRows);
  };

  const handleSupplierChange = (index, event) => {
    const newData = [...data];
    newData[index].selectedSupplierId = event.target.value;
    setData(newData);
  };

  // Handler for year select change
  const handleYearChange = (index, event) => {
    const newData = [...data];
    newData[index].selectedYear = event.target.value;
    setData(newData);
  };

  // Handler for quantity select change
  const handleQtyChange = (index, event) => {
    const newData = [...data];
    let selectedQty = parseInt(event.target.value);
    newData[index].selectedQty = selectedQty;
    setData(newData);
  };

  // Handler for picking list quantity change
  const handlePickingListQtyChange = (index, pickingListIndex, event) => {
    const newData = [...data];
    let selectedQty = parseInt(event.target.value);
    newData[index].pickingList[pickingListIndex].selectedQty = selectedQty;
    setData(newData);
  };

  const handleCellButtonClick = (pickingListItem) => {
    if (onCellSelect) {
      onCellSelect(
        pickingListItem.warehouseAreaId,
        pickingListItem.rack,
        pickingListItem.col,
        pickingListItem.shelf
      );
    }
  };

  return (
    <>
      <RowCountLabel count={data?.length} label={label} />
      <Table striped>
        <thead>
          <tr>
            {showSelectorCol && <th style={{ width: "64px" }}></th>}
            {showDocCol && <th>Documento</th>}
            {showProductCol && <th>Articolo</th>}
            {(movReason === 0 || movReason === 1) && <th>Fornitore</th>}
            {(movReason === 0 || movReason === -1) && <th>Cliente</th>}
            {showYearCol && (
              <th className="text-end" style={{ width: "100px" }}>
                Anno
              </th>
            )}
            <th className="text-end" style={{ width: "170px" }}>
              Qta
            </th>
            {(showPositionCol || showPickingList) && <th>Ubicazione</th>}
          </tr>
        </thead>
        <tbody>
          {data?.map((row, rowIndex) => (
            <React.Fragment key={`row-${idPrefix}-${row.id}`}>
              <tr>
                {showSelectorCol && (
                  <td className="text-center">
                    <CheckboxComponent
                      id={`sel-${idPrefix}-${row.id}`}
                      checked={row.selected}
                      onChange={() => handleCheckboxChange(row.id)}
                      hidden={movReason === -1 && row.noStock}
                    />
                  </td>
                )}
                {showDocCol && (
                  <td>
                    <ul className="list-unstyled">
                      <li>
                        {row.docTypeDescr}&nbsp;
                        {row.docNumb}
                        {row.docSeries?.trim() ? "/" + row.docSeries : ""}&nbsp;
                        {DataManager.Date.format(row.docDate, "dd/MM/yyyy")}
                        {/* <span className="half-parent-text">&nbsp;{row.id}</span> */}
                      </li>
                      {movReason === -1 && (
                        <li>
                          {row.customerDescr}&nbsp;
                          <span className="half-parent-text">
                            {row.shippingContact !== row.customerDescr
                              ? (row.shippingContact &&
                                  row.shippingContact
                                    .trim()
                                    .substring(0, 10)) ||
                                ""
                              : ""}
                          </span>
                        </li>
                      )}
                      <li>
                        <span className="half-parent-text">
                          {row.transpReasonDescr}
                        </span>
                      </li>
                    </ul>
                  </td>
                )}
                {showProductCol && (
                  <td>
                    <ProductLink {...row} showProductLink={!row.noStock && showProductLink} />
                  </td>
                )}
                {(movReason === 0 || movReason === 1) &&
                  (editSupplier ? (
                    <td>
                      <select
                        id={`supplier-${idPrefix}-${row.id}`}
                        value={row.selectedSupplierId || 0}
                        onChange={(event) =>
                          handleSupplierChange(rowIndex, event)
                        }
                        className="form-select"
                      >
                        {suppliers.map((supplier) => (
                          <option key={supplier.id} value={supplier.id}>
                            {supplier.descr}
                          </option>
                        ))}
                      </select>
                    </td>
                  ) : (
                    <td>
                      <ul className="list-unstyled">
                        <li>{row.supplierDescr}</li>
                        <li>
                          <span className="half-parent-text">
                            {row.supplierId}
                          </span>
                        </li>
                      </ul>
                    </td>
                  ))}
                {(movReason === 0 || movReason === -1) && (
                  <td>
                    <ul className="list-unstyled">
                      <li>{row.customerDescr}</li>
                      <li>
                        <span className="half-parent-text">
                          {row.customerId}
                        </span>
                      </li>
                    </ul>
                  </td>
                )}
                {showYearCol && (
                  <td className="text-end">
                    {editYear ? (
                      <select
                        id={`year-${idPrefix}-${row.id}`}
                        value={row.selectedYear || currentYear}
                        onChange={(event) => handleYearChange(rowIndex, event)}
                        className="form-select"
                      >
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    ) : (
                      row.productYear
                    )}
                  </td>
                )}
                <td className="text-end">
                  {editQty ? (
                    freeQty ? (
                      <div className="d-flex align-items-center justify-content-end">
                        <input
                          id={`qty-${idPrefix}-${row.id}`}
                          type="number" 
                          value={row.selectedQty || 0}
                          onChange={(event) => handleQtyChange(rowIndex, event)}
                          className="form-control text-end"
                          style={{ width: "100px" }}
                          min="0" 
                          step="1" 
                        />
                      </div>
                    ) : (
                      <div className="d-flex align-items-center justify-content-end">
                        <select
                          id={`qty-${idPrefix}-${row.id}`}
                          value={row.selectedQty || 0}
                          onChange={(event) => handleQtyChange(rowIndex, event)}
                          className="form-select text-end"
                          style={{ width: "100px" }}
                        >
                          {Array.from(
                            { length: row.qty },
                            (_, i) => row.qty - i
                          ).map((qty) => (
                            <option key={qty} value={qty}>
                              {qty}
                            </option>
                          ))}
                        </select>
                        &nbsp; / &nbsp;
                        <span>{DataManager.Decimal.format(row.qty, 0)}</span>
                      </div>
                    )
                  ) : (
                    <div className="d-flex align-items-center justify-content-end">
                      {showPickingList &&
                        row.pickingList &&
                        (row.pickingList?.reduce(
                          (sum, mov) => sum + mov.selectedQty,
                          0
                        ) === row.qty ? (
                          <CheckCircleIcon className="me-3 text-success" />
                        ) : (
                          <WarningIcon className="me-3 text-danger" />
                        ))}
                      {row.noStock && <FiberNewIcon className="me-3" />}
                      {DataManager.Decimal.format(row.qty, 0)}
                      {row.origQty &&
                        row.origQty !== row.qty &&
                        "/" + DataManager.Decimal.format(row.origQty, 0)}
                    </div>
                  )}
                </td>
                {(showPositionCol || showPickingList) && (
                  <td>
                    {showPositionCol && (
                      <ul className="list-unstyled">
                        <li>
                          {showPositionLink ? (
                            <a
                              href={`/celle?warehouseAreaId=${row.warehouseAreaId}&rack=${row.rack}&col=${row.col}&shelf=${row.shelf}`}
                            >
                              {`${row.warehouseAreaDescr}-${row.rack}-${row.col}-${row.shelf}`}
                              {row.lastMovData && (
                                <div className="half-parent-text">
                                  {`Ult. mov. ${row.lastMovData}`}
                                </div>
                              )}
                            </a>
                          ) : (
                            <>
                              {`${row.warehouseAreaDescr}-${row.rack}-${row.col}
                              -${row.shelf}`}
                              {row.lastMovData && (
                                <div className="half-parent-text">
                                  {`Ult. mov. ${row.lastMovData}`}
                                </div>
                              )}
                            </>
                          )}
                        </li>
                      </ul>
                    )}
                  </td>
                )}
              </tr>
              {showPickingList && row.pickingList && (
                <>
                  {row.pickingList.map((pickingListItem, pickingListIndex) => (
                    <tr
                      key={`row-${idPrefix}-${pickingListItem.id}-${pickingListIndex}`}
                    >
                      {/*
                      {showSelectorCol && <th style={{ width: "64px" }}></th>}
                      {showDocCol && <th>Documento</th>}
                      {showProductCol && <th>Articolo</th>}
                      {(movReason === 0 || movReason === 1) && <th>Fornitore</th>}
                      {(movReason === 0 || movReason === -1) && <th>Cliente</th>}
                      {showYearCol && (
                        <th className="text-end" style={{ width: "100px" }}>
                          Anno
                        </th>
                      )}
                      <th className="text-end" style={{ width: "170px" }}>
                        Qta
                      </th>
                      {(showPositionCol || showPickingList) && <th>Ubicazione</th>}                      
                      */}
                      <td
                        colSpan={
                          (showSelectorCol ? 1 : 0) +
                          (showDocCol ? 1 : 0) +
                          (showProductCol ? 1 : 0) +
                          (movReason === 0 || movReason === 1 || movReason === -1 ? 1 : 0) +
                          (showYearCol ? 1 : 0) +
                          1 //qta
                        }
                      >
                        {/* <ul className="list-unstyled">  
                          <li>{
                          pickingListItem.rack +'-'+ row.rack}</li>
                          <li>{
                          pickingListItem.col  +'-'+ row.col}</li>
                          <li>{
                            pickingListItem.shelf  +'-'+ row.shelf}</li>
                          <li>{
                            pickingListItem.supplierId  +'-'+ row.supplierId}</li>
                          <li>{
                            pickingListItem.productYear  +'-'+ row.productYear}</li></ul> */}
                        <div className="d-flex align-items-center justify-content-end">
                          <div className="me-3 text-end">
                            <ul className="list-unstyled">
                              <li>{row.contactDescr}</li>
                              <li>
                                <span className="half-parent-text">
                                  {row.contactId}
                                </span>
                              </li>
                            </ul>
                          </div>
                          {editPickingList ? (
                            <>
                              <select
                                id={`qty-${idPrefix}-${row.id}-${pickingListItem.id}`}
                                value={pickingListItem.selectedQty || 0}
                                onChange={(event) =>
                                  handlePickingListQtyChange(
                                    rowIndex,
                                    pickingListIndex,
                                    event
                                  )
                                }
                                className="form-select text-end"
                                style={{ width: "100px" }}
                              >
                                {Array.from(
                                  { length: row.qty + 1 },
                                  (_, i) => row.qty - i
                                ).map((qty) => (
                                  <option key={qty} value={qty}>
                                    {qty}
                                  </option>
                                ))}
                              </select>
                              &nbsp; / &nbsp;
                              <span>
                                {DataManager.Decimal.format(row.qty, 0)}
                              </span>
                            </>
                          ) : (
                            <>
                              {DataManager.Decimal.format(
                                pickingListItem.qty,
                                0
                              )}
                            </>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div>
                            {onCellSelect ? (
                              <>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a
                                  href="#"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    handleCellButtonClick(pickingListItem);
                                  }}
                                >
                                  <ul className="list-unstyled">
                                    <li>
                                      {pickingListItem.warehouseAreaDescr}-
                                      {pickingListItem.rack}-
                                      {pickingListItem.col}-
                                      {pickingListItem.shelf}
                                    </li>
                                    <li>
                                      {pickingListItem.lastMovData && (
                                        <span className="half-parent-text">
                                          {`Ult. mov. ${pickingListItem.lastMovData}`}
                                        </span>
                                      )}
                                    </li>
                                  </ul>
                                </a>
                              </>
                            ) : (
                              <ul className="list-unstyled">
                                <li>
                                  {pickingListItem.warehouseAreaDescr}-
                                  {pickingListItem.rack}-{pickingListItem.col}-
                                  {pickingListItem.shelf}
                                </li>
                                <li>
                                  {pickingListItem.lastMovData && (
                                    <span className="half-parent-text">
                                      {`Ult. mov. ${pickingListItem.lastMovData}`}
                                    </span>
                                  )}
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <tr style={{ height: "64px" }}></tr>
                </>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default CellProducts;
