// DbContex.js
import React, { createContext, useState, useEffect } from "react";
import ApiManager from "../utils/ApiManager";
import { getLoginIsValid } from "../contexts/AuthContext";

// Crea il contesto
export const DbContext = createContext();

let _dbInfo = null;

export const DbProvider = ({ children }) => {
  const [dbInfo, setDbInfo] = useState(null);

  const loadDbInfo = async () => {
    await ApiManager.Top.dbInfo(
      (data) => {
        setDbInfo(data);
      },
      () => {},
      () => {}
    );
  };

  useEffect(() => {
    if (getLoginIsValid() && process.env.NODE_ENV === "development" && !dbInfo) {
      loadDbInfo();
    }
  }, []);
  
  useEffect(() => {
    _dbInfo = dbInfo;
  }, [dbInfo]);


  return (
    <DbContext.Provider value={{ dbInfo }}>
      {children}
    </DbContext.Provider>
  );
};

export const getDbInfo = () => _dbInfo;
