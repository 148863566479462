import React, { createContext, useState, useContext } from 'react';

const LoadingContext = createContext();

export const useLoadingContext = () => useContext(LoadingContext);

let _setLoading = () => {};

export const LoadingProvider = ({ children }) => {
  const [activeRequests, setActiveRequests] = useState(0);

  _setLoading = (isLoading) => {
    setActiveRequests(prevCount => isLoading ? prevCount + 1 : Math.max(prevCount - 1, 0));
  };

  return (
    <LoadingContext.Provider value={{ isLoading: activeRequests > 0, setLoading: _setLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const setLoading = (isLoading) => _setLoading(isLoading);
