import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputGroup,
  Button,
  Row,
  Col,
  Form,
  Table,
} from "react-bootstrap";
import ClearIcon from "@mui/icons-material/Clear";

import ApiManager from "../utils/ApiManager"; // Adjust the path as per your directory structure
import SearchIcon from "@mui/icons-material/Search";
import TodayIcon from "@mui/icons-material/Today";
import DataManager from "../utils/DataManager";
import CellProducts from "./CellProducts";

const PickingLists = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState([]);
  const [firstLoading, setFirstLoading] = useState(true);
  const [movReason, setMovReason] = useState(-1);

  const fetchData = async () => {
    await ApiManager.Top.pickingLists(
      { fromDate: startDate, toDate: endDate, movReason: movReason },
      (data) => {
        setData(data.pickingLists);
      },
      (error) => {
        console.error("Error fetching products:", error);
      },
      () => {}
    );
  };

  useEffect(() => {
    setToday();
  }, []);

  useEffect(() => {
    if (firstLoading === true && startDate && endDate && data?.length === 0) {
      fetchData();
      setFirstLoading(false);
    } else if (firstLoading === false && data?.length > 0) {
      setData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, movReason]);

  const setToday = () => {
    let now = DataManager.Date.toIso(new Date());
    setStartDate(now);
    setEndDate(now);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleClearStartDate = (e) => {
    setStartDate("");
  };

  const handleClearEndDate = (e) => {
    setEndDate("");
  };

  const handleSearchClick = async () => {
    await fetchData();
  };

  const handleSameDate = (e) => {
    setEndDate(startDate);
  };

  return (
    <div className="date-range-filter">
      <Row className="mb-3">
        <Col>
          <Form.Label htmlFor="movReasonSelect">Tipo di Movimento</Form.Label>
          <Form.Select
            id="movReasonSelect"
            value={movReason}
            onChange={(e) => setMovReason(parseInt(e.target.value))}
          >
            <option value={1}>Carichi</option>
            <option value={-1}>Scarichi</option>
          </Form.Select>
        </Col>
        <Col>
          <Form.Label htmlFor="startDateInput">Da data</Form.Label>
          <InputGroup className="mb-3">
            {startDate && (
              <Button
                title="Cancella"
                variant="outline-secondary"
                id="button-addon3"
                onClick={handleClearStartDate}
              >
                <ClearIcon />
              </Button>
            )}
            <FormControl
              type="date"
              id="startDateInput"
              aria-label="Start date"
              aria-describedby="button-addon2"
              value={startDate}
              onChange={handleStartDateChange}
            />
          </InputGroup>
        </Col>
        <Col
          xs="auto"
          className="d-flex align-items-center justify-content-center"
        >
          <Button
            variant="outline-secondary"
            id="button-addon4"
            onClick={handleSameDate}
            className="mt-2"
          >
            =
          </Button>
        </Col>
        <Col>
          <Form.Label htmlFor="endDateInput">A data</Form.Label>
          <InputGroup className="mb-3">
            {endDate && (
              <Button
                title="Cancella"
                variant="outline-secondary"
                id="button-addon5"
                onClick={handleClearEndDate}
              >
                <ClearIcon />
              </Button>
            )}
            <FormControl
              type="date"
              id="endDateInput"
              placeholder="A data"
              aria-label="End date"
              aria-describedby="button-addon2"
              value={endDate}
              onChange={handleEndDateChange}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="mb-3 text-end">
          <Button
            title="Oggi"
            id="buttonToday"
            variant="primary"
            onClick={setToday}
          >
            <TodayIcon />
          </Button>
          &nbsp;
          <Button
            title="Cerca"
            variant="primary"
            id="buttonSearch"
            onClick={handleSearchClick}
            disabled={!startDate || !endDate}
          >
            <SearchIcon  />
          </Button>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data?.map((row, index) => (
                <React.Fragment key={`row-${row.id}`}>
                  <tr>
                    <td>{`Lista ${DataManager.Date.format(
                      row.docDate,
                      "yyyy/MM/dd"
                    )}-${DataManager.Decimal.format(row.docNumb, 0, 6)}`}</td>
                  </tr>
                  <tr>
                    <td>
                      <CellProducts
                        idPrefix={`PickingLists-${index}`}
                        data={row.cellProducts}
                        showDocCol={true}
                        showYearCol={true}
                        showPositionCol={true}
                        movReason={movReason === 1 ? 1 : 0}
                        label={"Articoli"}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default PickingLists;
