import React, { useEffect, useRef, useState } from "react";
import { Modal, Alert, Button } from "react-bootstrap";
import CellSelector from "./CellSelector";
import CellProducts from "./CellProducts";
import ApiManager from "../utils/ApiManager"; // Importa ApiManager
import "./Modal.css";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { v4 as uuidv4 } from "uuid";

const ModalMovementIncomings = ({
  movReason,
  movements,
  onCheckedChange,
  showModal,
  setShowModal,
  onSaveModal,
}) => {
  const [error, setError] = useState(null);
  const [selectedWarehouseAreaId, setSelectedWarehouseAreaId] = useState("");
  const [selectedWarehouseAreaDescr, setSelectedWarehouseAreaDescr] =
    useState("");
  const [selectedRack, setSelectedRack] = useState("");
  const [selectedCol, setSelectedCol] = useState("");
  const [selectedShelf, setSelectedShelf] = useState("");
  const [receivingList, setReceivingList] = useState([]);
  const [validationError, setValidationError] = useState("");
  const warehouseAreaRef = useRef(null);

  useEffect(() => {
    if (onCheckedChange) {
      onCheckedChange(receivingList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receivingList]);

  const fetchData = async () => {
    setError(null);
    await ApiManager.Top.cellProducts(
      {
        pickingList: movements
          ?.filter((row) => row.selected)
          .map((row) => ({
            productId: row.productId,
            qty: row.qty,
          })),
      },
      (data) => {
        let tmpReceivingList = movements
          ?.filter((row) => row.selected)
          .map((row) => ({
            ...row,
            pickingList: data?.filter(
              (item) => item.productId === row.productId && item.id !== row.id
            ),
            selectedYear: new Date().getFullYear(),
            selectedQty: row.qty,
          }));

        setReceivingList(tmpReceivingList);
      },
      (error) => {
        setError(error);
      },
      () => {}
    );
  };

  useEffect(() => {
    if (showModal) {
      setValidationError("");
      fetchData();
      if (warehouseAreaRef.current) {
        warehouseAreaRef.current.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const closeModal = () => {
    setShowModal(false);
  };

  const validateFields = () => {
    if (!selectedWarehouseAreaId) {
      return "L'area del magazzino è obbligatoria.";
    }
    if (!selectedRack) {
      return "Il rack è obbligatorio.";
    }
    if (!selectedCol) {
      return "La colonna è obbligatoria.";
    }
    if (!selectedShelf) {
      return "Lo scaffale è obbligatorio.";
    }
    return "";
  };

  const saveModal = async () => {
    const error = validateFields();
    if (error) {
      setValidationError(error);
      return;
    }

    const newGuid = uuidv4();

    const incomings = receivingList.map((movement) => ({
      ...movement,
      productYear: movement.selectedYear,
      qty: movement.selectedQty,
    }));

    await ApiManager.Top.stockIncomings(
      newGuid,
      incomings,
      selectedWarehouseAreaId,
      selectedWarehouseAreaDescr,
      selectedRack,
      selectedCol,
      selectedShelf,
      (data) => {
        onSaveModal(data);
        setShowModal(false);
      },
      (error) => {
        console.error("Errore durante il salvataggio:", error);
        setValidationError("Errore durante il salvataggio.");
      },
      () => {}
    );
  };

  const handleCellSelection = (warehouseAreaId, rack, col, shelf) => {
    setSelectedWarehouseAreaId(warehouseAreaId);
    setSelectedRack(rack);
    setSelectedCol(col);
    setSelectedShelf(shelf);
  };

  return (
    <Modal
      className="incomingModals"
      show={showModal}
      onHide={closeModal}
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <strong className="header-text">Carico</strong>
      </Modal.Header>
      <Modal.Body>
        {validationError && <Alert variant="danger">{validationError}</Alert>}
        <CellProducts
          idPrefix="ModalMovementIncomings"
          label="Articoli da caricare"
          showDocCol={true}
          showSelectorCol={true}
          showYearCol={true}
          editYear={true}
          editQty={true}
          data={receivingList.filter((row) => row.selected)}
          onCellSelect={handleCellSelection}
          setData={setReceivingList}
          movReason={movReason}
          showPickingList={true}
        />
        <strong className="header-text">Ubicazione di destinazione</strong>
        <CellSelector
          idPrefix="ModalMovementIncomings"
          ref={warehouseAreaRef}
          selectedWarehouseAreaId={selectedWarehouseAreaId}
          setSelectedWarehouseAreaId={setSelectedWarehouseAreaId}
          selectedWarehouseAreaDescr={selectedWarehouseAreaDescr}
          setSelectedWarehouseAreaDescr={setSelectedWarehouseAreaDescr}
          selectedRack={selectedRack}
          setSelectedRack={setSelectedRack}
          selectedCol={selectedCol}
          setSelectedCol={setSelectedCol}
          selectedShelf={selectedShelf}
          setSelectedShelf={setSelectedShelf}
          editMode={true}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          title="Salva"
          onClick={saveModal}
          disabled={
            !selectedWarehouseAreaId ||
            !selectedRack ||
            !selectedCol ||
            !selectedShelf
          }
        >
          <SaveAltIcon />
        </Button>
        {/* <Button variant="secondary" onClick={closeModal}>
          Chiudi
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalMovementIncomings;
