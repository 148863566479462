import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Form, Button, Container, Image, Alert } from "react-bootstrap";
import LockOpenIcon from "@mui/icons-material/LockOpen";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const [rememberMe, setRememberMe] = useState(false);
  const { login, logout } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleRememberMeChange = (e) => {
  //   setRememberMe(e.target.checked);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (await login(username, password, rememberMe)) {
    if (await login(username, password)) {
      navigate("/");
    }
  };

  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center min-vh-100"
      style={{ marginTop: "var(--body-margin-top-negative)" }}
    >
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-4 text-center">
          <ul className="list-unstyled">
            <li>
              <Image
                src="/logo-top.png"
                alt="Top Ruote Logo"
                style={{ width: "auto", height: "100px" }}
              />
            </li>
            <li>
              <span className="header-text">
                <strong>Gestione Magazzino</strong>
              </span>
            </li>
          </ul>
        </Form.Group>
        <Form.Group className="mb-4" >
          <Form.Control
            id="username"
            name="username"
            autoComplete="username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
          />
        </Form.Group>
        <Form.Group className="mb-4" >
          <Form.Control
            id="password"
            name="password"
            autoComplete="current-password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </Form.Group>
        <Form.Group className="mb-3 d-flex justify-content-between align-items-center">
          {/* <div>
            <Form.Check
              type="checkbox"
              id="rememberMeCheckbox"
              label="Ricordami"
              checked={rememberMe}
              onChange={handleRememberMeChange}
            />
          </div> */}
          <div>
            <Button type="submit" variant="primary" title="Login">
              <LockOpenIcon />
            </Button>
          </div>
        </Form.Group>
        {error && (
          <Alert variant="danger" className="mt-3">
            {error}
          </Alert>
        )}
      </Form>
    </Container>
  );
};

export default Login;
