import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import ApiManager from "../utils/ApiManager"; // Importa ApiManager

import "./Movements.css";
import ModalMovementIncomings from "./ModalMovementIncomings";
import ModalMovementOutgoings from "./ModalMovementOutgoings";
import CellProducts from "./CellProducts";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const Movements = ({ movReason }) => {
  const [showIncomingsModal, setShowIncomingsModal] = useState(false);
  const [showOutgoingsModal, setShowOutgoingsModal] = useState(false);
  const [movements, setMovements] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [contactId, setContactId] = useState(0); // Default to 0 (all contacts)

  const handleCheckedChange = (list) => {
    if (list.length > 0) {
      const updatedMovements = movements.map((movement) => {
        const listItem = list.find((pl) => pl.id === movement.id);

        if (listItem) {
          // Usa il valore selected dell'ultimo elemento trovato (o di un criterio specifico)
          const selected = listItem.selected;

          // Ritorna un nuovo oggetto movement con selected aggiornato
          return {
            ...movement,
            selected: selected,
          };
        }

        return movement; // Se non trovato, ritorna l'oggetto movement originale
      });

      setMovements(updatedMovements);
    }
  };

  const fetchData = async () => {
    await ApiManager.Top.movements(
      { movReason: movReason, contactId: !contacts?.length ? -1 : contactId },
      (data) => {
        if (!contacts?.length) {
          setContacts(data.contacts);
        }
        setMovements( data.movements);

        // //DEBUG
        // let movementsTmp = data.movements.map((movement) =>
        //   movement.id === "TR24A07913A0003"
        //     ? { ...movement, qty: 35, origQty: 35 }
        //     : movement
        // );
        // setMovements(movementsTmp);
      },
      (error) => {
        console.error("Error fetching movements:", error);
      },
      () => {}
    );
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movReason, contactId]); // Dipendenza da movReason e contactId

  const handleContactChange = (event) => {
    setContactId(parseInt(event.target.value));
  };

  const handleOpenModal = () => {
    if (movReason === 1) {
      setShowIncomingsModal(true);
    } else if (movReason === -1) {
      setShowOutgoingsModal(true);
    }
  };

  const handleSaveModal = async (data) => {
    fetchData(); // Refresh data after modal save
  };

  return (
    <div className="movements">
      <Row className="mb-3">
        <Col>
          <Form.Group controlId="contactSelect">
            <Form.Label className="half-parent-text">
              Filtra per {movReason === 1 ? "Fornitore" : "Cliente"}
            </Form.Label>
            <Form.Control
              as="select"
              value={contactId}
              onChange={handleContactChange}
            >
              <option value="0">{"* Tutte *"}</option>
              {contacts?.map((contact) => (
                <option key={contact.id} value={contact.id}>
                  {contact.descr} - {contact.id}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="text-end">
          {(movReason === 1 || movReason === -1) && (
            <Button
              title={movReason === 1 ? "Carica" : "Scarica"}
              onClick={handleOpenModal}
              variant="primary"
              className="float-end"
              disabled={
                movements?.filter((movement) => movement.selected).length === 0
              }
            >
              {movReason === 1 ? <CloudUploadIcon /> : <CloudDownloadIcon />}
            </Button>
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <CellProducts
            idPrefix="Movements"
            data={movements}
            setData={setMovements}
            movReason={movReason}
            showDocCol={true}
            showSelectorCol={true}
            showProductLink={true}
          />
        </Col>
      </Row>
      <ModalMovementIncomings
        movements={movements}
        onCheckedChange={handleCheckedChange}
        showModal={showIncomingsModal}
        setShowModal={setShowIncomingsModal}
        onSaveModal={handleSaveModal}
      />
      <ModalMovementOutgoings
        movements={movements}
        setMovements={setMovements}
        onCheckedChange={handleCheckedChange}
        showModal={showOutgoingsModal}
        setShowModal={setShowOutgoingsModal}
        onSaveModal={handleSaveModal}
      />
    </div>
  );
};

export default Movements;
