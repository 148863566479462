import React from "react";
import { Link } from "react-router-dom";

const ProductLink = ({
  productId,
  productTypeDescr,
  productEan,
  productSupplCode,
  productDescr,
  showProductLink,
}) => {
  let result = (
    <ul className="list-unstyled">
      <li>
        {productDescr}
      </li>
      <li>
        <span className="half-parent-text">
          {productTypeDescr} {productId.trim()}
          {productEan?.trim() ? " | " + productEan.trim() : ""}
          {productSupplCode?.trim() ? " | " + productSupplCode.trim() : ""}
        </span>
      </li>
    </ul>
  );

  if (showProductLink) {
    result = (
      <Link to={`/articoli?productId=${productId}`} className="card-link">
        {result}
      </Link>
    );
  }

  return result;
};

export default ProductLink;
