import React, { useState, useEffect, useRef } from "react";
import { FormControl, InputGroup, Button, Row, Col } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import ApiManager from "../utils/ApiManager"; // Adjust the path as per your directory structure
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";

const ProductSearch = ({ onlyInStock, onProductChanged, onProductAdded }) => {
  const [searchPattern, setSearchPattern] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    // Check for productId in query string at component startup
    const params = new URLSearchParams(window.location.search);
    const productIdFromQuery = params.get("productId");

    if (productIdFromQuery) {
      setSearchPattern(productIdFromQuery);
      setSelectedProductId(productIdFromQuery);
      fetchProducts(productIdFromQuery);
    }
  }, []);

  useEffect(() => {
    if (onProductChanged) {
      if (selectedProductId) {
        onProductChanged({ id: selectedProductId });
      } else {
        onProductChanged({});
      }
    }
  }, [selectedProductId]);

  const fetchProducts = async (query) => {
    await ApiManager.Top.products(
      {
        searchPattern: query,
        productId: selectedProductId,
        onlyInStock: onlyInStock,
      },
      (data) => {
        setProducts(data.products);
        if (data.products.length > 0) {
          setSelectedProductId(data.products[0].id);
        } else {
          setSelectedProductId("");
        }
      },
      (error) => {
        console.error("Error fetching products:", error);
      },
      () => {}
    );
  };

  const handleSearchChange = (e) => {
    setSearchPattern(e.target.value);
  };

  const handleSearchClick = () => {
    fetchProducts(searchPattern);
  };

  const handleProductSelect = (e) => {
    setSelectedProductId(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchClick();
    }
  };

  const handleClearClick = () => {
    setSearchPattern("");
    setProducts([]);
    setSelectedProductId("");
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleAddProduct = () => {
    if (onProductAdded) {
      onProductAdded(
        products?.find((product) => product.id === selectedProductId)
      );
    }
  };

  return (
    <>
      <Row className="mb-3">
        <Col>
          <InputGroup className="mb-3">
            {searchPattern && (
              <Button
                title="Cancella"
                variant="outline-secondary"
                id="button-addon3"
                onClick={handleClearClick}
              >
                <ClearIcon />
              </Button>
            )}
            <FormControl
              id="searchPattern"
              ref={inputRef}
              type="text"
              placeholder="cerca per codice, ean, descrizione..."
              aria-label="Product search"
              aria-describedby="button-addon2"
              value={searchPattern}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
            />
            <Button
              title="Cerca"
              variant="primary"
              id="button-addon2"
              onClick={handleSearchClick}
            >
              <SearchIcon />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <InputGroup className="mb-3">
            <FormControl
              as="select"
              id="productSelect"
              value={selectedProductId}
              onChange={handleProductSelect}
            >
              {products?.map((product) => (
                <option key={product.id} value={product.id}>
                  {product.descr}
                </option>
              ))}
            </FormControl>
            {onProductAdded && (
              <Button
                title="Add Product"
                variant="primary"
                id="button-addon4"
                onClick={handleAddProduct}
              >
                <PlaylistAddIcon />
              </Button>
            )}
          </InputGroup>
        </Col>
      </Row>
    </>
  );
};

export default ProductSearch;
