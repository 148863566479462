import React, { useState, useEffect } from "react";
import { useLoadingContext } from "../contexts/LoadingContext";
import "./Loading.css";

const Loading = () => {
  const { isLoading } = useLoadingContext();
  const [showLoading, setShowLoading] = useState(false);
  const [loadingTimer, setLoadingTimer] = useState(null);

  useEffect(() => {
    if (isLoading) {
      // Imposta un timer solo se isLoading diventa vero
      setLoadingTimer(setTimeout(() => {
        setShowLoading(true);
      }, 500)); // Delay di 0.5 secondi (500 millisecondi)
    } else {
      // Se isLoading diventa falso, cancella il timer se è stato impostato
      if (loadingTimer) {
        clearTimeout(loadingTimer);
        setLoadingTimer(null);
      }
      setShowLoading(false);
    }

    // Cleanup: assicura di cancellare il timer quando il componente viene smontato o quando isLoading cambia
    return () => {
      if (loadingTimer) {
        clearTimeout(loadingTimer);
        setLoadingTimer(null);
      }
    };
  }, [isLoading]);

  return showLoading && (
    <div className="loading-overlay">
      <div className="loading-content">
        <ul className="list-unstyled">
          <li>
            <img src="/loading.gif" alt="Loading..." />
          </li>
          <li>
            <strong className="header-text half-parent-text">Loading...</strong>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Loading;
