// AppContext.js
import React, { createContext, useState, useEffect } from 'react';

// Crea il contesto
export const AppContext = createContext();

export const AppProvider = ({ children }) => {

  useEffect(() => {
  }, []);

  return (
    <AppContext.Provider value={{ }}>
      {children}
    </AppContext.Provider>
  );
};
