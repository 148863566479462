import React, { useEffect, useState } from "react";
import { Button, Modal, Alert } from "react-bootstrap";
import CellProducts from "./CellProducts";

import ApiManager from "../utils/ApiManager"; // Adjust the path as per your directory structure
import "./Modal.css";
import { v4 as uuidv4 } from "uuid";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

const ModalMovementOutgoings = ({
  movements,
  onCheckedChange,
  showModal,
  setShowModal,
  onSaveModal,
}) => {
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState("");
  const [pickingList, setPickingList] = useState([]);

  useEffect(() => {
    if (onCheckedChange) {
      onCheckedChange(pickingList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickingList]);

  const distributeQuantities = (tmpPickingList) => {
    const productQuantities = {};
    tmpPickingList.forEach((row) => {
      if (row.pickingList)
        if (!productQuantities[row.productId]) {
          productQuantities[row.productId] = {
            required: row.qty,
            totalQty: 0,
          };
        }
      productQuantities[row.productId].totalQty += row.qty;
    });

    tmpPickingList.forEach((row) => {
      row.pickingList.forEach((pickingListItem) => {
        if (productQuantities[pickingListItem.productId].required > 0) {
          const availableQty = Math.min(
            pickingListItem.qty,
            productQuantities[pickingListItem.productId].required
          );
          pickingListItem.selectedQty = availableQty; // Assegna la massima quantità possibile fino alla quantità richiesta
          productQuantities[pickingListItem.productId].required -= availableQty;
        } else {
          pickingListItem.selectedQty = 0;
        }
      });
    });

    return tmpPickingList;
  };

  const fetchData = async () => {
    setError(null);
    await ApiManager.Top.cellProducts(
      {
        pickingList: movements
          ?.filter((row) => row.selected)
          .map((row) => ({
            productId: row.productId,
            qty: row.qty,
          })),
      },
      (data) => {
        let tmpPickingList = movements
          ?.filter((row) => row.selected)
          .map((row) => ({
            ...row,
            pickingList: data?.filter(
              (item) => item.productId === row.productId && item.id !== row.id
            ),
          }));

        tmpPickingList = distributeQuantities(tmpPickingList);

        setPickingList(tmpPickingList);
      },
      (error) => {
        setError(error);
      },
      () => {}
    );
  };

  useEffect(() => {
    if (showModal) {
      setValidationError("");
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const closeModal = () => {
    setShowModal(false);
  };

  const validateFields = () => {
    // Implement your validation logic here if needed
    return "";
  };

  const saveModal = async () => {
    const error = validateFields();
    if (error) {
      setValidationError(error);
      return;
    }
    const newGuid = uuidv4();

    const outgoings = pickingList.map((movement) => ({
      ...movement,
      qty: movement.selectedQty,
    }));

    await ApiManager.Top.stockOutgoings(
      newGuid,
      outgoings,
      (data) => {
        onSaveModal(data);
        setShowModal(false);
      },
      (error) => {
        console.error("Error during API call:", error);
        setValidationError("Errore durante il salvataggio. Riprova.");
      },
      () => {}
    );
  };

  return (
    <Modal
      className="incomingModals"
      show={showModal}
      onHide={closeModal}
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <strong className="header-text">Scarico</strong>
      </Modal.Header>
      <Modal.Body>
        {validationError && <Alert variant="danger">{validationError}</Alert>}
        <CellProducts
          idPrefix="ModalMovementOutgoings"
          label="Articoli da scaricare"
          data={pickingList.filter((row) => row.selected)}
          setData={setPickingList}
          showDocCol={true}
          showSelectorCol={true}
          showPickingList={true}
          editPickingList={true}
          movReason={-1}
        />
        {/* <CellProducts
          label="Ubicazioni"
          data={suggestedPickingList}
          setData={setSuggestedPickingList}
          editQty={true}
          showProductCol={false}
          useRequiredQty={true}
          showYearCol={true}
          showPositionCol={true}
        /> */}
      </Modal.Body>
      <Modal.Footer>
        <Button
          title="Salva"
          variant="primary"
          onClick={saveModal}
          disabled={pickingList?.some(
            (row) =>
              row.selected &&
              row.pickingList?.reduce(
                (sum, mov) => sum + mov.selectedQty,
                0
              ) !== row.qty
          )}
        >
          <SaveAltIcon />
        </Button>
        {/* <Button variant="secondary" onClick={closeModal}>
          Chiudi
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalMovementOutgoings;
