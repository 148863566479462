import React from "react";
import NavMenu from "./NavMenu";
import { useAuth } from "../contexts/AuthContext";

const Layout = (props) => {
  // Utilizza useAuth per accedere allo stato di autenticazione
  const { loginIsValid } = useAuth();

  return (
    <div>
      {loginIsValid && <NavMenu />} 
      <div className="container-fluid" tag="main">
        {props.children}
      </div>
    </div>
  );
};

Layout.displayName = "Layout";

export default Layout;
