import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import urlParse from "url-parse";
import { Avatar } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

const NavMenu = () => {
  const { currentUser, logout } = useAuth();
  const location = useLocation();

  const handleLogout = () => {
    logout();
    window.location.href = "/";
  };

  const isActive = (path) => {
    const parsedUrl = urlParse(location.pathname, true);
    const parsedPath = urlParse(path, true);
    return (
      parsedUrl.pathname === parsedPath.pathname &&
      JSON.stringify(parsedUrl.query) === JSON.stringify(parsedPath.query)
    );
  };

  const LinkButton = ({ to, children }) => (
    <Nav.Link
      as={Link}
      to={to}
      className={`btn-link ${isActive(to) ? "active font-weight-bold" : ""}`}
      disabled={isActive(to)}
      style={{
        fontWeight: isActive(to) ? "bold" : "normal",
        color: isActive(to) ? "var(--bs-body-color) !important" : "",
      }}
    >
      {children}
    </Nav.Link>
  );

  return (
    <header>
      <Navbar expand="lg" variant="light" bg="light" className="fixed-top border-bottom box-shadow mb-3">
        <Navbar.Brand as={Link} to="/">
          <img
            src="/logo-top.png"
            alt="Top Ruote Logo"
            style={{ width: "auto", height: "50px" }}
          />
        </Navbar.Brand>
        <div className="d-flex align-items-center">
          <Nav.Item className="d-flex align-items-center me-2">
            <Dropdown
            >
              <Dropdown.Toggle
                as="div"
                className="p-0 d-flex align-items-center"
                style={{ cursor: "pointer" }}
              >
                <Avatar >
                  <PersonIcon />
                </Avatar>
                {currentUser && <span className="ms-2">{currentUser.descr}</span>}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav.Item>
          {/* <Navbar.Toggle
            aria-controls="navbar-nav"
          /> */}
        </div>
        <Navbar.Collapse
          id="navbar-nav"
        >
          <Nav className="ms-auto align-items-center">
            <Nav.Item>
              <LinkButton to="/carichi">Carichi</LinkButton>
            </Nav.Item>
            <Nav.Item>
              <LinkButton to="/scarichi">Scarichi</LinkButton>
            </Nav.Item>
            <Nav.Item>
              <LinkButton to="/liste">Liste</LinkButton>
            </Nav.Item>
            <Nav.Item>
              <LinkButton to="/celle">Celle</LinkButton>
            </Nav.Item>
            <Nav.Item>
              <LinkButton to="/articoli">Articoli</LinkButton>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default NavMenu;
